
body{
    margin: 0;
    padding:0;
    font-family: 'Inter';
    background-color: #FAFAFA

}
.myDiv{
    display: none;

}

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base{
    .h1{
        @apply text-black dark:text-white pb-4  text-[29px] tracking-tighter font-medium ;
    }
    .flex{
        @apply hidden
    }

    .selected{
        @apply  text-[#F58A39] font-semibold text-[16px] md:text-[14px] sm:text-[12px] xm:text-[10px];
    }

    .un-selected{
        @apply  text-[#505887]  text-[16px] md:text-[14px] sm:text-[12px] xm:text-[10px] font-semibold;
    }
    .welcome-text{
        @apply text-black ml-[40px] dark:text-white  text-[29px] lg:text-[25px] md:text-[25px]  tracking-tighter  font-normal
    }
    .meeting-text{
        @apply text-black   text-[30px] lg:text-[30px] md:text-[25px] sm:text-[22px] font-normal
    }
    .user-text{
        @apply text-black  dark:text-white pb-6   text-[29px] lg:text-[25px] md:text-[25px] tracking-tighter  font-normal
    }
    .grid-dashboard{
        @apply grid grid-cols-12 lg:grid-cols-12 md:grid-cols-12 pt-[25px]  xm:grid-cols-12  gap-0  bg-[#FAFAFA]
    }
    .dashboard-sub-grid{
        @apply col-span-9
    }
    .dashboard-project-box{
        @apply grid grid-cols-9
    }
    .dashboard-project-box-inner{
        @apply col-span-6
    }
    .dashboard-sub-grid-whats-new{
        @apply col-span-3  px-[15px]
    }
    .dashboard-sub-grid-rewards{
        @apply  col-span-3 bg-[#FAFAFA]
    }
    .dashboard-sub-grid-upcoming-meetings{
        @apply xm:mx-5 tab:mx-5 tab:col-span-12 mobile:col-span-12 small:col-span-12 mobile:mx-1 xm:col-span-12 xm:mt-16  tab:mt-16 mobile:mt-16  col-span-3 lg:col-span-3 md:col-span-3 bg-white
    }
    .appboard-grid{
        @apply grid grid-cols-12 px-[30px]
    }
    .appboard-sub-grid{
        @apply col-span-12
    }
    .appboard-grid-project{
        @apply  bg-white border-1 border-[#EDF0F4] rounded-t-[15px] mb-[62px] md:mb-[30px]
    }
    .appboard-grid-rewards{
        @apply lg:col-span-3 md:col-span-3 sm:col-span-5 xm:col-span-12 small:col-span-12  tab:col-span-12 mobile:col-span-12 bg-[#FAFAFA]
    }
    .meetingboard-grid{
        @apply grid grid-cols-4  pl-[30px]
    }
    .meetingboard-grid-sub{
        @apply col-span-3 lg:col-span-3 md:col-span-3 sm:col-span-3
    }
    .meetingboard-grid-project{
        @apply grid grid-cols-4  gap-4 mx-6 pt-12 pl-8 pb-28
    }
    .meetingboard-grid-project-div{
        @apply bg-[#f37a20] w-[3px] h-[41px] mt-4 mr-2
    }
    .meetingboard-grid-project-div-title{
        @apply font-medium text-[#505887] text-[13px] lg:text-[12px]  md:text-[11px]
    }
    .meetingboard-grid-project-div-link{
        @apply text-[#f37a20] pt-2 text-[10px] lg:text-[9px] md:text-[8px]
    }
    .meetingboard-grid-project-div-date{
        @apply text-[10px] font-medium pl-2 text-blue-400
    }
    .meetingboard-grid-rewards{
        @apply col-span-1 lg:col-span-1  md:col-span-1 sm:col-span-1  bg-[#FAFAFA]
    }
    .invoice-grid-chart{
        @apply col-span-1
        }
    .invoice-grid{
        @apply grid grid-cols-4 gap-2
    }
    .invoice-grid-table{
        @apply border-1 border-[#EDF0F4] rounded-t-[15px]
    }
    .invoice-grid-sub{
        @apply col-span-3
    }
    .invoice-grid-sub-full{
        @apply col-span-4
    }
    .invoice-grid-sub-section{
        @apply col-span-1 h-[100%] pt-[20px] border-1 border-[#EDF0F4] rounded-t-[15px] bg-white
    }
    .invoice-grid-sub-section-title{
        @apply text-center text-[16px] lg:text-[14px] md:text-[14px]
    }
    .invoice-grid-sub-section-history{
        @apply flex px-[16px] gap-[14px]
    }
    .invoice-grid-sub-section-box{
        @apply rounded-t-[15px] justify-center border-1 border-[#EFF1F5] p-[20px] sm:p-[15px] xm:p-[10px] items-center w-[100%]
    }
    .invoice-grid-sub-section-box-title{
        @apply text-center justify-center   text-[#2A303B] font-bold text-[30px] lg:text-[25px] md:text-[20px] sm:text-[18px] xm:text-[16px]
    }
    .invoice-grid-sub-section-box-desc{
        @apply text-center text-[#2A303B]  font-medium text-[13px] md:text-[11px]  sm:text-[9px] xm:text-[8px]
    }
    .invoice-grid-sub-section-box-date{
        @apply text-center text-[#2A303B]  text-[13px] lg:text-[10px] md:text-[8px] sm:text-[7px] xm:text-[6px] font-bold opacity-[0.6]
    }

    .downloadcontent-tabs{
        @apply mobile:hidden small:hidden tab:hidden
    }
    .downloadcontent-overview{
        @apply bg-white border-1 p-4 pt-6  border-[#F4F5F8] rounded-md
    }
    .rewardspoints-grid{
        @apply grid grid-cols-2 mx-4 gap-0
    }
    .rewardspoints-grid-rewards{
        @apply mt-[65px] lg:col-span-1 col-span-1 md:col-span-1 sm:col-span-4  tab:col-span-4 mobile:col-span-4 xm:col-span-4 small:col-span-4
    }
    .rewardspoints-grid-admin{
        @apply lg:col-span-1 col-span-1 md:col-span-1 sm:col-span-4  tab:col-span-4 mobile:col-span-4 xm:col-span-4 small:col-span-4
    }
    .rewardspoints-grid-points{
        @apply p-6 lg:w-[406px] md:w-[300px] mobile:p-4
    }
    .rewardspoints-grid-points-heading{
        @apply text-[#2A303C] text-[18px] font-bold
    }
    .rewardspoints-grid-points-count{
        @apply text-[#F37A20] mt-[2px] text-center rounded-xl w-[46px] h-[24px] text-[16px] font-bold   bg-[#FFF9F0]
    }
    .rewardspoints-grid-progress{
        @apply lg:col-span-1 col-span-1 md:col-span-1 sm:col-span-1  tab:col-span-2 mobile:col-span-2 xm:col-span-2 small:col-span-2
    }
    .signin-form{
        @apply col-span-3 flex justify-center items-center bg-[#080A17]
    }
    .signin-form-div-title{
        @apply text-[#F37A20] pl-[5px] font-normal text-[16px]
    }
    .signin-form-div-cancel{
        @apply text-white cursor-pointer text-[14px]
    }
    .signin-form-div-save{
        @apply text-[14px] font-bold h-[40px] text-white w-[107px] rounded-md bg-orange-500 border-none outline-none text-center items-center
    }
    .settings-dp{
        @apply hover:opacity-90 cursor-pointer relative w-[137px] h-[137px]  rounded-full overflow-hidden
    }
    .settings-cover{
        @apply tab:w-[122px] xm:w-[122px] sm:w-[122px] tab:h-[122px] xm:h-[122px] sm:h-[122px] mobile:w-[70px] small:w-[70px]  mobile:h-[70px] small:h-[70px]
    }
    .settings-picker{
        @apply absolute top-0 left-0 right-0 w-full h-full opacity-0 cursor-pointer rounded-full
    }
    .settings-form{
        @apply flex tab:justify-center mobile:justify-center small:justify-center tab:grid tab:items-center mobile:flex mobile:items-center small:flex small:items-center flex-wrap gap-5
    }
    .settings-input{
        @apply w-[100%]  h-[45px] border-1 border-[#E5E5ED] rounded-md pl-4  placeholder:text-[#768396] placeholder:text-[14px] placeholder:font-medium
    }
    .settings-email-div{
        @apply w-[100%] mt-[19px] mobile:grid mobile:justify-center small:grid small:justify-center
    }
    .settings-email-div-img{
        @apply absolute inset-y-0 left-[-5px] flex items-center p-2 pointer-events-none
    }
    .settings-input-email{
        @apply w-[94%] tab:w-[308px] mobile:w-[250px] small:w-[200px] h-[45px] border-1 border-[#E5E5ED] rounded-md pl-10 placeholder:text-[#768396] placeholder:text-[14px] placeholder:font-medium
    }
    .input-password{
        @apply w-[441px] mobile:w-[250px] small:w-[200px] h-[45px] border-1 border-white bg-[#080A17] text-white rounded-md pl-4  placeholder:text-[#B7B7B7] placeholder:text-[14px] placeholder:font-medium

    }
    .input-email{
        @apply w-[441px] tab:w-[308px] text-white mobile:w-[250px] small:w-[200px] h-[45px] bg-[#080A17] border-1 border-white rounded-md pl-10 placeholder:text-[#B7B7B7] placeholder:text-[14px] placeholder:font-medium
    }
    .settings-password-div{
        @apply flex tab:justify-center mobile:justify-center small:justify-center tab:grid tab:items-center mobile:grid mobile:items-center small:grid small:items-center flex-wrap gap-5
    }
    .settings-form-submit{
        @apply flex gap-4 mt-[27px] mobile:justify-center small:justify-center
    }
    .settings-form-submit-cancel{
        @apply w-[80px] text-[#000000] text-[14px] md:h-[35px] md:text-[12px] font-medium h-[40px] border rounded-md bg-white border-[#D9DADE]
    }
    .settings-form-submit-update{
        @apply w-[150px] text-[#000000] text-[14px] md:h-[35px] md:text-[12px] font-medium h-[40px] border rounded-md bg-white border-[#D9DADE]
    }
    .settings-form-submit-save{
        @apply w-[64px] text-[14px] font-bold h-[40px] md:h-[35px] md:text-[12px] border rounded-md bg-[#F37A20] text-white border-[#F37A20]
    }
    .settings-rewards{
        @apply col-span-1 lg:col-span-1 md:col-span-1  xm:col-span-4 sm:col-span-4 tab:col-span-4 mobile:col-span-4 small:col-span-4 bg-[#FAFAFA]
    }
    .appboard-title{
        @apply flex justify-between pb-[41px] md:pb-[30px] xm:pb-[25px]
    }
    .appboard-grid-project-title{
        @apply text-[#131313] text-[20px] md:text-[18px] sm:text-[16px] xm:text-[14px] font-medium
    }
    .appboard-grid-project-wrapper{
        @apply py-[20px] md:py-[15px] bg-[white] rounded-t-[15px] border-1 border-[#EDF0F4] h-[550px] overflow-y-auto
    }
    .appboard-overview-project-wrapper{
        @apply py-[20px] md:py-[15px] bg-[white] rounded-t-[15px] border-1 border-[#EDF0F4] h-[auto] overflow-y-auto
    }
    .invoice-grid-sub-section-paid{
        @apply   text-center text-[#7CAB0E] lg:text-[16px] sm:text-[12px] xm:text-[10px] md:text-[14px]  font-medium
    }
    .invoice-grid-sub-section-value{
        @apply  text-center font-extrabold lg:text-[20px] sm:text-[16px] xm:text-[14px] md:text-[18px]
    }
    .invoice-grid-sub-section-remaining{
        @apply  text-center text-[#F5822C] lg:text-[16px] sm:text-[12px] xm:text-[10px] md:text-[14px] font-medium
    }
    .invoice-grid-sub-section-remaining-value{
        @apply  text-center font-extrabold lg:text-[20px] sm:text-[16px] xm:text-[14px] md:text-[18px]
    }
}

